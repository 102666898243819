import React from 'react';
import './App.css';
import Hello from './Hello';

function App() {
  return (
    <Hello />
  );
}

export default App;
